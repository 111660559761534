import Logo from "../assets/logo.png";
import { Dropdown } from "flowbite-react";
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Navbar({ authDispatch, authState }) {
  const [loading, setLoading] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const logoutUser = async () => {
    authDispatch({ type: "logged-out" });
    if (isAuthenticated) {
      const logoutRequest = {
        account: account,
      };
      instance.logoutRedirect(logoutRequest);
    } else {
      setTimeout(() => {
        navigate("/");
      }, 700);
    }
  };

  useEffect(() => {
    let samlToken = localStorage.getItem("saml-token");
    if (samlToken && samlToken.length > 0) {
      setLoading(true);
      fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
        headers: {
          Authorization: `Bearer ${samlToken}`,
          responseType: "blob",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          let objectURL = URL.createObjectURL(blob);
          setProfilePic(objectURL);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        })
        .catch((err) => {
          console.log(err);
          setProfilePic(null);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        });
    }
  }, []);

  const openEarnings = () => {
    window.open("https://my.adp.com/static/redbox/#/dashboard", "_blank");
  };
  return (
    <header
      aria-label="Site Header"
      className="bg-gray-800 text-white sticky top-0 z-50 w-full"
    >
      <div className="mx-auto flex h-16 max-w-screen-2xl items-center justify-between sm:px-6 lg:px-8">
        <div className="flex items-center">
          <button type="button" className="p-2 sm:mr-4 lg:hidden"></button>

          <a href="/" className="flex justify-start items-center gap-3">
            <img src={Logo} className="w-10 h-10" alt="logo" />
            <p className="text-white text-lg antialiased font-medium">
              Hypertek HR Portal
            </p>
            <span className="sr-only">Logo</span>
          </a>
        </div>

        <div className="flex flex-1 items-center justify-end">
          <nav
            aria-label="Site Nav"
            className="hidden lg:flex lg:gap-4 lg:text-xs lg:font-bold lg:uppercase lg:tracking-wide lg:text-gray-500"
          >
            <a
              href="/"
              className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current hover:text-red-700"
            >
              Home
            </a>
            <a
              href="/news"
              className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current hover:text-red-700"
            >
              News
            </a>

            <a
              href="/orgChart"
              className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current hover:text-red-700"
            >
              Org. Chart
            </a>

            <a
              href="/directory"
              className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current hover:text-red-700"
            >
              Directory
            </a>

            <a
              href="/help"
              className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current hover:text-red-700"
            >
              Help
            </a>
          </nav>

          <div className="ml-5 flex items-center">
            <div className="flex items-center">
              <Dropdown
                label={
                  profilePic ? (
                    <img
                      src={profilePic}
                      className="object-cover rounded-full w-10 h-10"
                      alt="profile"
                    />
                  ) : (
                    <img
                      src={Logo}
                      className="object-cover rounded-full w-10 h-10"
                      alt="profile"
                    />
                  )
                }
                arrowIcon={false}
                inline={true}
                className="font-sans"
              >
                <Dropdown.Header>
                  <span className="block text-sm">
                    {authState.user.firstName} {authState.user.lastName}
                  </span>
                  <span className="block truncate text-sm font-medium">
                    {authState.user.email}
                  </span>
                </Dropdown.Header>
                <Dropdown.Item>Profile</Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/pto")}>
                  Time Off
                </Dropdown.Item>
                <Dropdown.Item onClick={() => openEarnings()}>
                  Earnings
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => logoutUser()}>
                  Sign out
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
