import { useState, useEffect } from "react";
import Logo from "./assets/logoDarkWide.png";
import moment from "moment";
import { useMsal, useIsAuthenticated, useAccount } from "@azure/msal-react";
import { callMsGraph, callMsGroups } from "./actions/graph";
import { SignIn } from "./actions/auth";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import animationData from "./assets/loginAnim.json";

const Login = ({ authState, authDispatch }) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  let isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const logoutUser = async () => {
    await instance.logoutRedirect({
      account: account,
      postLogoutRedirectUri: "https://contoso.com/loggedOut",
    });
  };

  useEffect(() => {
    if (account && !isAuthenticated && !authState.isAuth) {
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: account,
        })
        .then((response) => {
          if (response) {
            localStorage.setItem("saml-token", response.accessToken);
            callMsGraph(response.accessToken).then((data) => {
              callMsGroups(response.accessToken).then((groupData) => {
                let results = groupData.value;
                let groups = [];
                for (let i = 0; i < results.length; i++) {
                  if (results[i].displayName === "Software Development") {
                    groups.push(results[i].displayName);
                  }
                  if (results[i].displayName === "Managed IT") {
                    groups.push(results[i].displayName);
                  }
                }
                let toSend = {
                  groups: groups,
                  email: data.mail,
                  firstName: data.givenName,
                  lastName: data.surname,
                  id: data.id,
                  msToken: localStorage.getItem("saml-token"),
                };

                SignIn(toSend)
                  .then((res) => {
                    authDispatch({
                      type: "valid-login",
                      payload: {
                        isAuth: true,
                        role: res.data.user.role,
                        groups: res.data.user.groups,
                        user: res.data.user,
                        token: res.data.token,
                        samlToken: localStorage.getItem("saml-token"),
                      },
                    });
                    setTimeout(() => {
                      navigate("/");
                    }, 700);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            });
          }
        })
        .catch((error) => {
          console.log("No account");
        });
    }
  }, [account, instance]);

  return (
    <div className="w-screen h-screen overflow-hidden bg-white flex flex-row items-center">
      <div className="flex-grow w-full h-full relative">
        <div className="absolute w-full h-full bg-[#020722] z-[5] flex flex-col justify-center items-center">
          <Lottie
            animationData={animationData}
            loop={true}
            className="w-1/2 h-1/2"
          />
        </div>
        <div className="absolute w-full h-full bg-black bg-opacity-60 z-[8] flex flex-col justify-center items-center"></div>
        <div className="absolute w-full h-full flex flex-col justify-center items-center z-10">
          <p className="text-[4.25rem] leading-[4.5rem] font-medium text-white text-center">
            Hypertek Solutions
          </p>
          <p className="font-serif font-semibold uppercase text-2xl text-white text-center highlight">
            HR Portal
          </p>
        </div>
      </div>
      <div className="w-[700px] min-w-[500px] h-full flex flex-col justify-between items-center py-10">
        <a
          href="https://liveuptothehype.com"
          target="_blank"
          referrer="noreferrer"
        >
          <img src={Logo} className="w-[200px] h-auto" />
        </a>
        <div className="flex w-full flex-col justify-center items-center gap-4">
          <h1>Login with your Hypertek O365 Account</h1>
          <button
            className="px-12 py-2 border border-black uppercase text-black transition-all ease-in-out duration-150 text-sm hover:bg-black hover:text-white"
            onClick={() => instance.loginRedirect()}
          >
            Login
          </button>
        </div>
        <p className="text-xs">
          All Rights Reserved &copy; 2018 - {moment().format("YYYY")} |{" "}
          <a
            href="https://liveuptothehype.com"
            target="_blank"
            referrer="noreferrer"
            className="font-medium hover:text-hyperred transition-all ease-in-out duration-150"
          >
            Hypertek Solutions LLC
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
