import { useState } from "react";
import Navbar from "./components/navbar";

const Help = ({ authState, authDispatch }) => {
  return (
    <div className="w-full h-screen flex flex-col justify-start items-center font-sans">
      <Navbar
        className="w-full"
        authState={authState}
        authDispatch={authDispatch}
      />
      <div className="w-full h-full flex flex-col justify-start items-center py-8">
        <div className="flex flex-col justify-center items-center w-10/12 gap-4">
          <section className="bg-white text-gray-800">
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-lg text-center">
                <h2 className="text-3xl font-bold sm:text-4xl">
                  Need help with something?
                </h2>

                <p className="mt-4 text-gray-600">
                  Seek for your answer below or reach out by clicking on the
                  button below if you don't feel like your question is answered.
                </p>
              </div>

              <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2">
                <a
                  className="block rounded-xl border border-gray-50 p-8 shadow-xl transition hover:border-gray-600/10 hover:shadow-gray-600/10"
                  href="https://my.adp.com/static/redbox/#/dashboard"
                  target="_blank"
                  referrer="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                    />
                  </svg>

                  <h2 className="mt-4 text-xl font-bold text-gray-800">
                    Payroll
                  </h2>

                  <p className="mt-1 text-sm text-gray-600">
                    Access your payroll information, W-2 information, update
                    direct deposit information, and revise your tax information.
                  </p>
                </a>

                <a
                  className="block rounded-xl border border-gray-50 p-8 shadow-xl transition hover:border-gray-600/10 hover:shadow-gray-600/10"
                  href="https://docs.hypertek.solutions/kba?folder=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>

                  <h2 className="mt-4 text-xl font-bold text-gray-800">
                    Employee Handbook
                  </h2>

                  <p className="mt-1 text-sm text-gray-600">
                    Get guidance and information related to the organization's
                    history, mission, values, policies, procedures and benefits.
                  </p>
                </a>

                <a
                  className="block rounded-xl border border-gray-50 p-8 shadow-xl transition hover:border-gray-600/10 hover:shadow-gray-600/10"
                  href="/calendar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M10.5 8.25h3l-3 4.5h3"
                    />
                  </svg>

                  <h2 className="mt-4 text-xl font-bold text-gray-800">
                    Holidays Calendar
                  </h2>

                  <p className="mt-1 text-sm text-gray-600">
                    Refresh your memory of the holidays the Company observes.
                    Visit the official calendar for the full list of holidays
                    and events.
                  </p>
                </a>

                <a
                  className="block rounded-xl border border-gray-50 p-8 shadow-xl transition hover:border-gray-600/10 hover:shadow-gray-600/10"
                  href="/"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"
                    />
                  </svg>

                  <h2 className="mt-4 text-xl font-bold text-gray-800">
                    Employee Toolkit
                  </h2>

                  <p className="mt-1 text-sm text-gray-600">
                    Access documentation about tools and services used within
                    the organization and your team.
                  </p>
                </a>
                <a
                  className="block rounded-xl border border-gray-50 p-8 shadow-xl transition hover:border-gray-600/10 hover:shadow-gray-600/10"
                  href="/health"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                    />
                  </svg>

                  <h2 className="mt-4 text-xl font-bold text-gray-800">
                    Health Benefits
                  </h2>

                  <p className="mt-1 text-sm text-gray-600">
                    Access, view, and manage your health benefits. This provides
                    you access to your insurance and information about it.
                  </p>
                </a>
                <a
                  className="block rounded-xl border border-gray-50 p-8 shadow-xl transition hover:border-gray-600/10 hover:shadow-gray-600/10"
                  href="mailto:zac@liveuptothehype.com?subject=I have a question that's not answered on the Employee Portal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                    />
                  </svg>

                  <h2 className="mt-4 text-xl font-bold text-gray-800">
                    I Have Another Question
                  </h2>

                  <p className="mt-1 text-sm text-gray-600">
                    Is your question not answered? Click here to get in touch
                    and get most up to date answers.
                  </p>
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Help;
