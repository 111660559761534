import { useState } from "react";
import Navbar from "./components/navbar";

const Health = ({ authState, authDispatch }) => {
  return (
    <div className="w-full h-screen flex flex-col justify-start items-center font-sans">
      <Navbar
        className="w-full"
        authState={authState}
        authDispatch={authDispatch}
      />
      <div className="w-full h-full flex flex-col justify-start items-center py-8">
        <div className="flex flex-col justify-center items-center w-10/12 gap-4">
          <section className="bg-white text-gray-800">
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-lg text-center">
                <h2 className="text-3xl font-bold sm:text-4xl">
                  Health Benefits
                </h2>
                <p className="mt-4 text-gray-600">
                  Access your health benefits information, including your
                  individual member portals.
                </p>
              </div>

              <div className="mt-8 grid grid-cols-1 gap-8">
                <a
                  className="block rounded-xl border border-gray-50 p-8 shadow-xl transition hover:border-gray-600/10 hover:shadow-gray-600/10"
                  href="https://www.myallsaversconnect.com/"
                  target="_blank"
                  referrer="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    class="feather feather-activity"
                  >
                    <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                  </svg>

                  <h2 className="mt-4 text-xl font-bold text-gray-800">
                    Health Insurance
                  </h2>
                </a>

                <a
                  className="block rounded-xl border border-gray-50 p-8 shadow-xl transition hover:border-gray-600/10 hover:shadow-gray-600/10"
                  href="https://www.guardianlife.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    class="feather feather-activity"
                  >
                    <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                  </svg>

                  <h2 className="mt-4 text-xl font-bold text-gray-800">
                    Dental & Vision Insurance
                  </h2>
                </a>

                <a
                  className="block rounded-xl border border-gray-50 p-8 shadow-xl transition hover:border-gray-600/10 hover:shadow-gray-600/10"
                  href="https://www.guardianlife.com/"
                  target="_blank"
                  referrer="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    class="feather feather-activity"
                  >
                    <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                  </svg>

                  <h2 className="mt-4 text-xl font-bold text-gray-800">
                    Life and Accidental Death & Dismemberment Insurance
                  </h2>
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Health;
