import { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { GetUser } from "./actions/auth";
import Homepage from "./homepage";
import OrgChart from "./orgChart";
import NewPTORequest from "./time-off/new-pto";
import Directory from "./directory";
import Help from "./help";
import News from "./news";
import Calendar from "./calendar";
import Login from "./login";
import PTORequests from "./time-off/pto";
import Health from "./health";

const App = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (token) {
      GetUser()
        .then((response) => {
          if (response.data.message === "Unauthorized User | LOGOUT") {
            authDispatch({ type: "logged-out" });
            localStorage.removeItem("auth-token");
            setLoading(false);
          } else {
            authDispatch({
              type: "valid-login",
              payload: {
                token: localStorage.getItem("auth-token"),
                role: response.data.role,
                groups: response.data.groups,
                user: response.data,
                samlToken: "",
              },
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          authDispatch({ type: "logged-out" });
          localStorage.removeItem("auth-token");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [authDispatch]);

  return !loading ? (
    <div className="w-full h-full min-w-screen min-h-screen">
      <Routes>
        <Route
          path="/login"
          element={
            authState.isAuth ? (
              <Navigate to="/" />
            ) : (
              <Login authState={authState} authDispatch={authDispatch} />
            )
          }
        />
        <Route
          path="/calendar"
          element={
            authState.isAuth ? (
              <Calendar authState={authState} authDispatch={authDispatch} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/news"
          element={
            authState.isAuth ? (
              <News authState={authState} authDispatch={authDispatch} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/help"
          element={
            authState.isAuth ? (
              <Help authState={authState} authDispatch={authDispatch} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/health"
          element={
            authState.isAuth ? (
              <Health authState={authState} authDispatch={authDispatch} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/directory"
          element={
            authState.isAuth ? (
              <Directory authState={authState} authDispatch={authDispatch} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/orgChart"
          element={
            authState.isAuth ? (
              <OrgChart authState={authState} authDispatch={authDispatch} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/pto/new"
          element={
            authState.isAuth ? (
              <NewPTORequest
                authState={authState}
                authDispatch={authDispatch}
              />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/pto"
          element={
            authState.isAuth ? (
              <PTORequests authState={authState} authDispatch={authDispatch} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/"
          element={
            authState.isAuth ? (
              <Homepage authState={authState} authDispatch={authDispatch} />
            ) : (
              <Login authState={authState} authDispatch={authDispatch} />
            )
          }
        />
        <Route
          index
          element={
            authState.isAuth ? (
              <Homepage authState={authState} authDispatch={authDispatch} />
            ) : (
              <Login authState={authState} authDispatch={authDispatch} />
            )
          }
        />
      </Routes>
    </div>
  ) : null;
};

export default App;
