import * as msal from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "4620592a-d436-40bd-b5d9-735496690544", //Hypertek
    // clientId: "626c571a-4fef-4e4e-a881-dfb30fe67b6d", //DD9
    authority: `https://login.microsoftonline.com/e8d658ef-530d-4259-9be5-f7b295fa1ffa`, //Hypertek
    // authority: `https://login.microsoftonline.com/a63db04f-57ce-451c-a569-0363f1ba598b`, //DD9
    // redirectUri: "hyperionOne://loginUser",
    redirectUri: "https://hr.hypertek.solutions/",
    postLogoutRedirectUri: "https://hr.hypertek.solutions",
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export { msalInstance };
