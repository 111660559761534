import React, { useState } from "react";
import Navbar from "./components/navbar";
import { Tree, TreeNode } from "react-organizational-chart";
import _ from "lodash";
import clsx from "clsx";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import organization from "./org.json";

import { User } from "@styled-icons/boxicons-regular/User";
import { Rocket } from "@styled-icons/entypo/Rocket";
import { ChevronDown } from "@styled-icons/evaicons-solid/ChevronDown";
import { ChevronUp } from "@styled-icons/evaicons-solid/ChevronUp";
import { Card as NewCard, Avatar, Badge, Button } from "antd";

const classes = {
  root: {
    background: "white",
    display: "inline-block",
    borderRadius: 16,
  },
  expand: {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#ECECF4",
  },
};

function Organization({ org, onCollapse, collapsed }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "account",
    drop: () => ({ name: org.tradingName }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = canDrop && isOver;
  let backgroundColor = "white";
  if (isActive) {
    backgroundColor = "#ddffd2";
  } else if (canDrop) {
    backgroundColor = "#ffeedc";
  }
  return (
    <NewCard ref={drop} style={{ width: 300, margin: "0 auto" }}>
      <NewCard.Meta
        avatar={
          <Badge
            style={{
              cursor: "pointer",
              visibility: collapsed ? "visible" : "hidden",
            }}
            color="#f20c2e"
            showZero={false}
            overlap="circle"
            count={1}
            onClick={onCollapse}
            className="mt-2"
          >
            <Avatar className="bg-hyperslate">
              <Rocket
                style={{ color: "#dcdfe2", marginBottom: "3px" }}
                size={20}
              />
            </Avatar>
          </Badge>
        }
        title={org.tradingName}
        action={null}
      />
      <Button
        type="text"
        icon={
          collapsed ? (
            <ChevronDown style={{ color: "#3d4044" }} size={24} />
          ) : (
            <ChevronUp style={{ color: "#3d4044" }} size={24} />
          )
        }
        size={"middle"}
        onClick={onCollapse}
        className={clsx(classes.expand, {
          [classes.expandOpen]: !collapsed,
        })}
      />
    </NewCard>
  );
}
function Account({ a }) {
  const [{ isDragging }, drag] = useDrag({
    item: { name: a.name, type: "account" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.4 : 1;
  return (
    <NewCard className="inline-block" ref={drag} style={{ width: 300 }}>
      <NewCard.Meta
        avatar={
          <Avatar className={classes.avatar}>
            <User style={{ color: "#3d4044" }} size={24} />
          </Avatar>
        }
        title={a.name}
        description={a.title}
      />
    </NewCard>
  );
}
function Product({ p }) {
  return (
    <NewCard className="inline-block" style={{ width: 300 }}>
      <NewCard.Meta
        avatar={
          <Avatar className={classes.avatar}>
            <User style={{ color: "#3d4044" }} size={24} />
          </Avatar>
        }
        title={p.name}
        description={p.title}
      />
    </NewCard>
  );
}
function Node({ o, parent }) {
  const [collapsed, setCollapsed] = React.useState(o.collapsed);
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };
  React.useEffect(() => {
    o.collapsed = collapsed;
  });
  const T = parent
    ? TreeNode
    : (props) => (
        <Tree
          {...props}
          lineWidth={"2px"}
          lineColor={"#bbc"}
          lineBorderRadius={"12px"}
        >
          {props.children}
        </Tree>
      );
  return collapsed ? (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    />
  ) : (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    >
      {_.map(o.account, (a) => (
        <TreeNode label={<Account a={a} />}>
          {a.product
            ? a.product.map((prod) => <TreeNode label={<Product p={prod} />} />)
            : null}
        </TreeNode>
      ))}
      {_.map(o.organizationChildRelationship, (c) => (
        <Node o={c} parent={o} />
      ))}
    </T>
  );
}

const OrganizationalChart = ({ authState, authDispatch }) => {
  return (
    <div className="w-full h-screen flex flex-col justify-start items-center font-sans">
      <Navbar
        className="w-full"
        authState={authState}
        authDispatch={authDispatch}
      />
      <div className="w-full h-full flex flex-col justify-center items-center">
        <p className="font-bold text-2xl mb-6">Organizational Chart</p>
        <div className="w-full flex flex-row justify-center items-center h-4/5">
          <DndProvider backend={HTML5Backend}>
            <Node o={organization} />
          </DndProvider>
        </div>
      </div>
    </div>
  );
};
export default OrganizationalChart;
