import axiosInstance from "./axios";

export const SignIn = (data) => {
  return axiosInstance.post("/auth/login", data);
};

export const GetUser = () => {
  return axiosInstance.get("/auth/user");
};

export const SubmitPTORequest = (data) => {
  return axiosInstance.post("/auth/ptoRequest", data);
};

export const GetPTORequests = () => {
  return axiosInstance.get("/auth/ptoRequests");
};

export const CancelPTORequest = (data) => {
  return axiosInstance.post("/auth/cancelPTO", data);
};
