import { useState } from "react";
import Navbar from "./components/navbar";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "./components/calendarStyle.css";
const localizer = momentLocalizer(moment);

const CalendarPage = ({ authState, authDispatch }) => {
  let holidays = [
    { date: "2022-01-01", title: "New Year's Day" },
    { date: "2022-05-29", title: "Memorial Day" },
    { date: "2022-07-04", title: "4th of July" },
    { date: "2022-09-04", title: "Labor Day" },
    { date: "2022-11-23", title: "Thanksgiving" },
    { date: "2022-12-24", title: "Christmas Eve" },
    { date: "2022-12-25", title: "Christmas Day" },
    { date: "2022-12-31", title: "New Year's Eve" },
  ];
  let paydays = [
    "2022-01-14",
    "2022-01-31",
    "2022-02-15",
    "2022-02-28",
    "2022-03-15",
    "2022-03-31",
    "2022-04-15",
    "2022-04-29",
    "2022-05-13",
    "2022-05-31",
    "2022-06-15",
    "2022-06-30",
    "2022-07-15",
    "2022-07-29",
    "2022-08-15",
    "2022-08-31",
    "2022-09-15",
    "2022-09-30",
    "2022-10-14",
    "2022-10-31",
    "2022-11-15",
    "2022-11-30",
    "2022-12-15",
    "2022-12-30",
    "2023-01-13",
    "2023-01-31",
    "2023-02-15",
    "2023-02-28",
    "2023-03-15",
    "2023-03-31",
    "2023-04-14",
    "2023-04-28",
    "2023-05-15",
    "2023-05-31",
    "2023-06-15",
    "2023-06-30",
    "2023-07-14",
    "2023-07-31",
    "2023-08-15",
    "2023-08-31",
    "2023-09-15",
    "2023-09-29",
    "2023-10-13",
    "2023-10-31",
    "2023-11-15",
    "2023-11-30",
    "2023-12-15",
    "2023-12-29",
  ];
  let holidaysFinal = [];
  for (let i = 0; i < 10; i++) {
    for (let holiday in holidays) {
      let toPush = {
        id: Math.random(),
        title: holidays[holiday].title,
        allDay: true,
        start: moment(holidays[holiday].date).add(i, "year"),
        end: moment(holidays[holiday].date).add(i, "year"),
      };
      holidaysFinal.push(toPush);
    }
  }
  for (let payday in paydays) {
    let toPush = {
      id: Math.random(),
      title: "Payday",
      allDay: true,
      start: moment(paydays[payday]),
      end: moment(paydays[payday]),
    };
    holidaysFinal.push(toPush);
  }

  return (
    <div className="w-full h-screen flex flex-col justify-start items-center font-sans">
      <Navbar
        className="w-full"
        authState={authState}
        authDispatch={authDispatch}
      />
      <div className="w-full h-full flex flex-col justify-start items-center py-8">
        <div className="flex flex-col justify-center items-center w-10/12 gap-4">
          <div className="mx-auto max-w-lg text-center">
            <h2 className="text-3xl font-bold sm:text-4xl mb-4">Calendar</h2>
          </div>
          <Calendar
            localizer={localizer}
            events={holidaysFinal}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "700px", width: "100%" }}
            views={["month"]}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;
