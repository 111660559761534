import { useState, useEffect } from "react";
import Navbar from "../components/navbar";
import { notification, Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { GetPTORequests, CancelPTORequest } from "../actions/auth";

const PTORequests = ({ authState, authDispatch }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetPTORequests()
        .then((res) => {
          setData(res.data);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        })
        .catch((err) => {
          setTimeout(() => {
            setLoading(false);
          }, 700);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const navigate = useNavigate();
  const columns = [
    {
      title: "#",
      dataIndex: "ptoId",
      key: "ptoNumber",
      render: (id) => data.findIndex((d) => d.ptoId === id),
    },
    {
      title: "PTO Type",
      dataIndex: "ptoType",
      key: "ptoType",
      render: (type) => renderPTOType(type),
    },
    {
      title: "Duration",
      dataIndex: "ptoId",
      key: "ptoDuration",
      render: (dur) => renderPTODuration(dur),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => renderPTOStatus(status),
    },
    {
      title: "Submitted At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => moment(record).format("ll"),
    },
    {
      title: "",
      key: "action",
      dataIndex: "ptoId",
      render: (record) => (
        <div className="flex flex-row justify-end gap-2 items-center">
          <button
            className="bg-white text-hyperred px-4 py-2 border border-hyperred font-mono uppercase antialiased tracking-wider font-semibold hover:bg-hyperred hover:text-white ease-in-out transition-all duration-150 disabled:bg-red-400 disabled:text-white disabled:border-red-400 disabled:cursor-not-allowed"
            disabled={checkStatus(record)}
            onClick={() => cancelPTO(record)}
          >
            Cancel
          </button>
        </div>
      ),
    },
  ];

  const renderPTOType = (type) => {
    switch (type) {
      case "sickTime":
        return "Sick Time";
      case "pto":
        return "Paid Time Off";
      case "bereavement":
        return "Bereavement";
      case "unpaidTimeOff":
        return "Unpaid Time Off";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const renderPTOStatus = (status) => {
    if (status === "pending") {
      return <Tag color="orange">Pending</Tag>;
    } else if (status === "denied") {
      return <Tag color="#f20c2e">Denied</Tag>;
    } else if (status === "approved") {
      return <Tag color="green">Approved</Tag>;
    } else if (status === "additionalReview") {
      return <Tag color="gold">Under Additional Review</Tag>;
    } else if (status === "cancelled") {
      return <Tag color="gray">Cancelled</Tag>;
    }
  };

  const renderPTODuration = (ptoId) => {
    let pto = data.find((d) => d.ptoId === ptoId);
    if (pto) {
      return `${moment(pto.startDate).format("ll")} - ${moment(
        pto.endDate
      ).format("ll")}`;
    }
  };

  const checkStatus = (ptoId) => {
    let pto = data.find((d) => d.ptoId === ptoId);
    if (pto) {
      if (moment(pto.startDate).isBefore(moment())) {
        return true;
      } else if (
        pto.status === "pending" ||
        pto.status === "additionalReview"
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const cancelPTO = (ptoId) => {
    setLoading(true);
    CancelPTORequest({ ptoId: ptoId })
      .then((res) => {
        notification.success({
          message: "Success",
          description: "PTO Request Cancelled",
          placement: "topRight",
        });
        reloadData();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: "Error cancelling PTO Request",
          placement: "topRight",
        });
        reloadData();
      });
  };

  const reloadData = () => {
    GetPTORequests()
      .then((res) => {
        setData(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 700);
      })
      .catch((err) => {
        setData([]);
        setTimeout(() => {
          setLoading(false);
        }, 700);
      });
  };

  return (
    <div className="w-full h-screen flex flex-col justify-start items-center font-sans">
      <Navbar
        className="w-full"
        authState={authState}
        authDispatch={authDispatch}
      />
      <div className="w-full h-full flex flex-col justify-start items-center py-8">
        {loading ? (
          <div className="flex flex-col justify-center items-center w-10/12 gap-4">
            <p className="font-medium text-2xl py-8">Loading PTO Requests</p>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center w-10/12 gap-4">
            {data.length > 0 ? (
              <div className="flex flex-row justify-between items-center w-full px-2 border-b border-gray-200">
                <p className="font-bold text-2xl py-8">PTO Requests</p>
                <button
                  className="bg-black text-white px-4 py-2 border border-black font-mono uppercase antialiased tracking-wider font-semibold hover:bg-white hover:text-black ease-in-out transition-all duration-150 disabled:bg-gray-500 disabled:border-gray-500 disabled:hover:text-white disabled:cursor-not-allowed text-sm"
                  onClick={() => navigate("/pto/new")}
                >
                  Request PTO
                </button>
              </div>
            ) : (
              <p className="font-bold text-2xl py-8">PTO Requests</p>
            )}
            {data.length > 0 ? (
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                style={{ width: "100%" }}
              />
            ) : (
              <div className="flex flex-col justify-center items-center">
                <p className="font-medium text-2xl py-8">
                  No PTO Requests found
                </p>
                <button
                  className="bg-black text-white px-4 py-2 border border-black font-mono uppercase antialiased tracking-wider font-semibold transition-all ease-in-out duration-150 hover:text-black hover:bg-white"
                  onClick={() => navigate("/pto/new")}
                >
                  Create a new PTO Request
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PTORequests;
