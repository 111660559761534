import { useState } from "react";
import Navbar from "../components/navbar";
import { DatePicker, Input, Select, notification } from "antd";
import dayjs from "dayjs";
import { SubmitPTORequest } from "../actions/auth";
import { useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const plans = [
  {
    name: "Sick Time",
  },
  {
    name: "Vacation / PTO",
  },
  {
    name: "Bereavement",
  },
  {
    name: "Unpaid Time Off",
  },
  {
    name: "Other",
  },
];

const NewPTORequest = ({ authState, authDispatch }) => {
  const [ptoType, setPTOType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reason, setReason] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  const updateDates = (val) => {
    setStartDate(dayjs(val[0]).toJSON());
    setEndDate(dayjs(val[1]).toJSON());
  };

  const submitRequest = () => {
    let failed = false;
    setLoading(true);
    if (!ptoType) {
      failed = true;
      setLoading(false);
      notification.error({
        message: "Error",
        description: "Please select a type of time off request",
        placement: "topRight",
      });
    }
    if (!failed && !startDate) {
      failed = true;
      setLoading(false);
      notification.error({
        message: "Error",
        description: "Please select a start date",
        placement: "topRight",
      });
    }
    if (!failed && !endDate) {
      failed = true;
      setLoading(false);
      notification.error({
        message: "Error",
        description: "Please select an end date",
        placement: "topRight",
      });
    }
    if (!failed && !reason) {
      failed = true;
      setLoading(false);
      notification.error({
        message: "Error",
        description: "Please enter a reason for your request",
        placement: "topRight",
      });
    }
    if (!failed && reason && reason.length < 20) {
      failed = true;
      setLoading(false);
      notification.error({
        message: "Error",
        description: "Please enter a more descriptive reason for your request",
        placement: "topRight",
      });
    }
    if (!failed) {
      let dataToSend = {
        ptoType: ptoType,
        startDate: startDate,
        endDate: endDate,
        reason: reason,
      };
      SubmitPTORequest(dataToSend)
        .then((res) => {
          notification.success({
            message: "Success",
            description: "Your request has been submitted",
            placement: "topRight",
          });
          setTimeout(() => {
            navigate("/pto");
          }, 1200);
        })
        .catch((err) => {
          setLoading(false);
          notification.error({
            message: "Error",
            description: "We've encountered an error, please try again.",
            placement: "topRight",
          });
          setTimeout(() => {
            navigate("/pto");
          }, 1200);
        });
    } else {
      setLoading(false);
      notification.error({
        message: "Error",
        description: "Something went wrong, please try again",
        placement: "topRight",
      });
    }
  };

  return (
    <div className="w-full h-screen flex flex-col justify-start items-center font-sans">
      <Navbar
        className="w-full"
        authState={authState}
        authDispatch={authDispatch}
      />
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="flex flex-row justify-center items-center w-9/12">
          <p className="font-bold text-2xl">New Paid-Time-Off Request</p>
        </div>
        <div className="w-full px-4 py-4">
          <div className="w-[500px] mx-auto bg-hypergray px-10 pt-10 pb-6 rounded-md shadow-lg gap-3 flex flex-col justify-start">
            <p className="text-sm font-semibold">Type of time off request</p>
            <Select
              placeholder="Select One"
              className="w-full"
              onChange={(val) => setPTOType(val)}
              options={[
                {
                  value: "sickTime",
                  label: "Sick Time",
                },
                {
                  value: "pto",
                  label: "Vacation / PTO",
                },
                {
                  value: "bereavement",
                  label: "Bereavement",
                },
                {
                  value: "unpaidTimeOff",
                  label: "Unpaid Time Off",
                },
                {
                  value: "other",
                  label: "Other",
                },
              ]}
            />
            <p className="text-sm font-semibold">Days Requested Off</p>
            <RangePicker
              className="w-full"
              disabledDate={disabledDate}
              onChange={(v) => updateDates(v)}
            />
            <p className="text-sm font-semibold">Enter the reason of leave:</p>
            <TextArea
              rows={5}
              placeholder="Briefly describe the reasoning behind your absence request"
              className="resize-none"
              style={{ resize: "none" }}
              onChange={(e) => setReason(e.target.value)}
            />
            <div className="flex flex-row justify-end items-center gap-4">
              <button
                className="font-mono border border-black px-4 py-2 uppercase font-medium cursor-pointer hover:bg-black hover:text-white transition-all ease-in-out duration-150 disabled:bg-gray-400 disabled:text-white disabled:border-gray-400 disabled:cursor-not-allowed"
                disabled={loading}
                onClick={() => navigate("/pto")}
              >
                Cancel
              </button>
              <button
                className="bg-black border border-black font-mono text-white px-4 py-2 uppercase hover:bg-transparent hover:text-black transition-all ease-in-out duration-150 disabled:bg-gray-400 disabled:text-white disabled:border-gray-400 disabled:cursor-not-allowed"
                disabled={loading}
                onClick={() => submitRequest()}
              >
                {loading ? "Processing..." : "Submit Request"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default NewPTORequest;
