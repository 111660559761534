import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./actions/saml";
import { AuthProvider, AuthConsumer } from "./context";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <MsalProvider instance={msalInstance}>
      <div className="dark">
        <AuthProvider>
          <AuthConsumer>
            {(authState) => (
              <BrowserRouter>
                <HelmetProvider>
                  <App
                    authState={authState}
                    authDispatch={authState.authDispatch}
                  />
                </HelmetProvider>
              </BrowserRouter>
            )}
          </AuthConsumer>
        </AuthProvider>
      </div>
    </MsalProvider>
  </React.StrictMode>
);
