import Navbar from "./components/navbar";
import moment from "moment";

const Homepage = ({ authState, authDispatch }) => {
  return (
    <div className="w-full h-full flex flex-col justify-start items-center font-sans">
      <Navbar
        className="w-full"
        authState={authState}
        authDispatch={authDispatch}
      />
      <div className="bg-gray-700 w-full py-4 flex flex-row justify-center items-center gap-8">
        <div className="flex flex-col w-1/5 h-full justify-center items-start">
          <p className="font-medium text-white">Sign up for benefits</p>
          <p className="font-light text-gray-400 text-sm">
            Open enrollment ends Monday, January 1st. Make sure you're covered{" "}
            <span className="text-hyperred underline font-semibold underline-offset-2">
              Enroll Now
            </span>
          </p>
        </div>
        <div className="flex flex-col w-1/5 h-full justify-center items-start">
          <p className="font-medium text-white">Sign up for benefits</p>
          <p className="font-light text-gray-400 text-sm">
            Open enrollment ends Monday, January 1st. Make sure you're covered{" "}
            <span className="text-hyperred underline font-semibold underline-offset-2">
              Enroll Now
            </span>
          </p>
        </div>
        <div className="flex flex-col w-1/5 h-full justify-center items-start">
          <p className="font-medium text-white">Sign up for benefits</p>
          <p className="font-light text-gray-400 text-sm">
            Open enrollment ends Monday, January 1st. Make sure you're covered{" "}
            <span className="text-hyperred underline font-semibold underline-offset-2">
              Enroll Now
            </span>
          </p>
        </div>
      </div>
      <div className="h-[40vh] w-10/12 bg-white flex flex-col justify-center items-center relative py-10">
        <div className="absolute mt-[4.5vh] ml-[4vw] top-0 left-0 bottom-0 z-0">
          <p className="text-[7rem] text-gray-200/80 font-semibold antialiased">
            This Week
          </p>
        </div>
        <p className="w-10/12 my-4 text-lg text-hyperred font-semibold antialiased z-10">
          Augusta, GA
        </p>
        <div className="w-10/12 flex flex-row justify-center items-center relative gap-10 z-10">
          <div className="w-1/3 h-full z-10">
            <p className="text-xl text-gray-400/80 font-medium">
              Wednesday, Jan 3rd
            </p>
            <p className="text-3xl font-semibold text-gray-800/90">
              Shooting at the Compound
            </p>
            <p className="text-md text-gray-400/80 font-medium">11am - 2pm</p>
            <p className="text-md text-gray-800/80 font-medium">
              Show off your new guns or have fun with your old ones. Join us for
              a shooting session at the compound.
            </p>
          </div>
          <div className="w-1/3 h-full z-10">
            <p className="text-xl text-gray-400/80 font-medium">
              Wednesday, Jan 3rd
            </p>
            <p className="text-3xl font-semibold text-gray-800/90">
              Shooting at the Compound
            </p>
            <p className="text-md text-gray-400/80 font-medium">11am - 2pm</p>
            <p className="text-md text-gray-800/80 font-medium">
              Show off your new guns or have fun with your old ones. Join us for
              a shooting session at the compound.
            </p>
          </div>
          <div className="w-1/3 h-full z-10">
            <p className="text-xl text-gray-400/80 font-medium">
              Wednesday, Jan 3rd
            </p>
            <p className="text-3xl font-semibold text-gray-800/90">
              Shooting at the Compound
            </p>
            <p className="text-md text-gray-400/80 font-medium">11am - 2pm</p>
            <p className="text-md text-gray-800/80 font-medium">
              Show off your new guns or have fun with your old ones. Join us for
              a shooting session at the compound.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center w-full bg-gray-800 h-[500px]">
        <img
          src="https://images.unsplash.com/photo-1583118443607-33f3731d09e3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80"
          className="flex flex-col justify-center items-start w-1/2 h-full object-cover"
          alt="happening-now"
        />
        <div className="flex flex-col justify-start items-start w-1/2 h-full py-16 px-20 gap-4">
          <p className="text-white text-3xl font-semibold antialiased">
            What's happening now?
          </p>
          <p className="text-white text-[2.5rem] leading-[2.75rem] font-bold antialiased">
            Nerds write some proper mung, caught in 4K.
          </p>
          <div className="w-full flex flex-row justify-start items-center gap-2 text-white mt-auto">
            <p className="text-gray-300 hover:text-hyperred transition ease-in-out duration-150 cursor-pointer uppercase font-mono">
              Watch Now
            </p>
            <div className="h-full w-[1px] bg-gray-400" />
            <p className="text-gray-300 hover:text-hyperred transition ease-in-out duration-150 cursor-pointer uppercase font-mono">
              Learn More &gt;
            </p>
          </div>
        </div>
      </div>
      <div className="w-10/12 max-w-[1200px] flex flex-col justify-start items-center bg-white py-14 mb-10">
        <p className="w-full my-10 text-4xl font-semibold">
          Global Announcements
        </p>
        <div className="w-full flex flex-row justify-start items-center gap-4">
          <article className="overflow-hidden rounded-lg shadow transition hover:shadow-lg w-1/3">
            <img
              alt="Calendar"
              src="https://images.unsplash.com/photo-1580227974546-fbd48825d991?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
              className="h-56 w-full object-cover"
            />
            <div className="bg-white p-4 sm:p-6">
              <time
                dateTime="2022-12-10"
                className="block text-xs text-gray-500"
              >
                10th Dec 2022
              </time>
              <a href="/calendar">
                <h3 className="mt-0.5 text-lg text-gray-900">
                  Company Observed Holidays
                </h3>
              </a>
              <p className="mt-2 text-sm leading-relaxed text-gray-500">
                Refresh your memory of the holidays the Company observes. Visit
                the official calendar for the full list of holidays and events.
              </p>
              <p className="w-full text-right text-gray-500 hover:text-hyperred cursor-pointer text-sm font-semibold font-mono uppercase">
                Learn More &gt;
              </p>
            </div>
          </article>
          <article className="overflow-hidden rounded-lg shadow transition hover:shadow-lg w-1/3">
            <img
              alt="Calendar"
              src="https://images.unsplash.com/photo-1580227974546-fbd48825d991?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
              className="h-56 w-full object-cover"
            />
            <div className="bg-white p-4 sm:p-6">
              <time
                dateTime="2022-12-10"
                className="block text-xs text-gray-500"
              >
                10th Dec 2022
              </time>
              <a href="/calendar">
                <h3 className="mt-0.5 text-lg text-gray-900">
                  Company Observed Holidays
                </h3>
              </a>
              <p className="mt-2 text-sm leading-relaxed text-gray-500">
                Refresh your memory of the holidays the Company observes. Visit
                the official calendar for the full list of holidays and events.
              </p>
              <p className="w-full text-right text-gray-500 hover:text-hyperred cursor-pointer text-sm font-semibold font-mono uppercase">
                Learn More &gt;
              </p>
            </div>
          </article>
          <article className="overflow-hidden rounded-lg shadow transition hover:shadow-lg w-1/3">
            <img
              alt="Calendar"
              src="https://images.unsplash.com/photo-1580227974546-fbd48825d991?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
              className="h-56 w-full object-cover"
            />
            <div className="bg-white p-4 sm:p-6">
              <time
                dateTime="2022-12-10"
                className="block text-xs text-gray-500"
              >
                10th Dec 2022
              </time>
              <a href="/calendar">
                <h3 className="mt-0.5 text-lg text-gray-900">
                  Company Observed Holidays
                </h3>
              </a>
              <p className="mt-2 text-sm leading-relaxed text-gray-500">
                Refresh your memory of the holidays the Company observes. Visit
                the official calendar for the full list of holidays and events.
              </p>
              <p className="w-full text-right text-gray-500 hover:text-hyperred cursor-pointer text-sm font-semibold font-mono uppercase">
                Learn More &gt;
              </p>
            </div>
          </article>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center w-full bg-slate-800 h-[400px]">
        <div className="flex flex-col justify-around items-start w-1/2 h-full py-12 px-20 gap-4">
          <p className="text-white text-xl font-mono uppercase font-semibold antialiased">
            Future of Hypertek
          </p>
          <p className="text-white text-[2.5rem] leading-[2.75rem] font-bold antialiased">
            Introducing new members
          </p>
          <p className="text-white text-[1.5rem] leading-[1.75rem] font-medium antialiased">
            Recently we've had a few new members join the team and we'd love to
            be the among the first to welcome them to the team, as well as
            introduce them to the rest.
          </p>
          <div className="w-full flex flex-row justify-end items-center gap-2 text-white mt-auto">
            <p className="text-gray-300 hover:text-hyperred transition ease-in-out duration-150 cursor-pointer font-mono uppercase">
              Read More &gt;
            </p>
          </div>
        </div>
        <img
          src="https://images.unsplash.com/photo-1472457847783-3d10540b03d7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80"
          className="flex flex-col justify-center items-start w-1/2 h-full object-cover object-top"
          alt="block-img"
        />
      </div>
      <div className="w-10/12 max-w-[1200px] flex flex-col justify-start items-center bg-white py-14 mb-10">
        <div className="w-full my-10 flex flex-row justify-start items-center gap-8">
          <p className="text-4xl font-semibold">Shoutouts</p>
          <button className="bg-black text-white px-4 py-2 font-mono uppercase tracking-wider font-medium border border-black hover:bg-white hover:text-black transition-all ease-in-out duration-150">
            Give a shoutout
          </button>
        </div>
        <div className="flex flex-row justify-start items-center gap-2 w-full">
          <div className="w-[400px] h-36 flex flex-row items-center justify-between gap-3">
            <div className="flex flex-col justify-center items-center w-[100px] h-full">
              <img
                src="https://images.unsplash.com/photo-1649077585967-b5decf6e032a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=659&q=80"
                className="w-24 h-24 object-cover rounded-full object-top"
                alt="user-profile"
              />
              <p className="text-gray-800 text-md font-semibold mt-2">
                John Smith
              </p>
              <p className="text-gray-500 text-sm font-mono uppercase">
                Managed IT
              </p>
            </div>
            <div className="h-full w-[300px] flex flex-col justify-center items-start">
              <p className="text-gray-800 text-md font-semibold">
                Appreciated by <span className="font-bold">Ben Dover</span>
              </p>
              <p className="text-gray-400 text-sm font-mono uppercase font-semibold">
                12 minutes ago
              </p>
              <p className="text-gray-600 font-bold flex-gro my-auto">
                John provided helpful insight in resolving a urgent matter for
                one of our bigger clients.
              </p>
            </div>
          </div>
          <div className="w-[400px] h-36 flex flex-row items-center justify-between gap-3">
            <div className="flex flex-col justify-center items-center w-[100px] h-full">
              <img
                src="https://images.unsplash.com/photo-1649077585967-b5decf6e032a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=659&q=80"
                className="w-24 h-24 object-cover rounded-full object-top"
                alt="user-profile"
              />
              <p className="text-gray-800 text-md font-semibold mt-2">
                John Smith
              </p>
              <p className="text-gray-500 text-sm font-mono uppercase">
                Managed IT
              </p>
            </div>
            <div className="h-full w-[300px] flex flex-col justify-center items-start">
              <p className="text-gray-800 text-md font-semibold">
                Appreciated by <span className="font-bold">Ben Dover</span>
              </p>
              <p className="text-gray-400 text-sm font-mono uppercase font-semibold">
                12 minutes ago
              </p>
              <p className="text-gray-600 font-bold flex-gro my-auto">
                John provided helpful insight in resolving a urgent matter for
                one of our bigger clients.
              </p>
            </div>
          </div>
          <div className="w-[400px] h-36 flex flex-row items-center justify-between gap-3">
            <div className="flex flex-col justify-center items-center w-[100px] h-full">
              <img
                src="https://images.unsplash.com/photo-1649077585967-b5decf6e032a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=659&q=80"
                className="w-24 h-24 object-cover rounded-full object-top"
                alt="user-profile"
              />
              <p className="text-gray-800 text-md font-semibold mt-2">
                John Smith
              </p>
              <p className="text-gray-500 text-sm font-mono uppercase">
                Managed IT
              </p>
            </div>
            <div className="h-full w-[300px] flex flex-col justify-center items-start">
              <p className="text-gray-800 text-md font-semibold">
                Appreciated by <span className="font-bold">Ben Dover</span>
              </p>
              <p className="text-gray-400 text-sm font-mono uppercase font-semibold">
                12 minutes ago
              </p>
              <p className="text-gray-600 font-bold flex-gro my-auto">
                John provided helpful insight in resolving a urgent matter for
                one of our bigger clients.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-800 w-full h-12 flex flex-row justify-center items-center">
        <div className="flex flex-row justify-between items-center w-10/12 max-w-[1200px]">
          <p className="text-gray-300 text-sm">Live Up To The Hype!</p>
          <p className="text-gray-300 hover:text-hyperred transition ease-in-out duration-150 cursor-pointer text-sm">
            All Rights Reserved &copy; 2019 - {moment().format("YYYY")} |{" "}
            <a
              href="https://liveuptothehype.com"
              target="_blank"
              referrer="noreferrer"
              className="font-medium hover:text-hyperred transition-all ease-in-out duration-150"
            >
              Hypertek Solutions LLC
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
