import { useState } from "react";
import Navbar from "./components/navbar";
import { Table, Space, Tag } from "antd";

const Directory = ({ authState, authDispatch }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Email Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Team",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            return (
              <Tag
                color={"#f20c2e"}
                key={tag}
                className="font-mono text-[14px]"
              >
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "",
      key: "action",
      dataIndex: "address",
      render: (email) => (
        <a
          className="bg-black text-white px-4 py-2 border border-black font-mono uppercase antialiased tracking-wider font-semibold cursor-pointer"
          href={`mailto:${email}`}
        >
          Send Email
        </a>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      name: "Zachary Lewallen",
      title: "Owner",
      address: "zac@liveuptothehype.com",
      tags: [
        "managed it",
        "software development",
        "cybersecurity",
        "datacenter",
      ],
    },
    {
      key: "2",
      name: "Alexander Cevicelow",
      title: "Chief Solutions Architek",
      address: "alex@liveuptothehype.com",
      tags: ["software development"],
    },
    {
      key: "3",
      name: "Anthony Reza",
      title: "Solutions Architek",
      address: "anthony@liveuptothehype.com",
      tags: ["managed it"],
    },
    {
      key: "4",
      name: "Brandon Greiner",
      title: "Tek Coordinator",
      address: "brandon@liveuptothehype.com",
      tags: ["managed it"],
    },
    {
      key: "5",
      name: "Dino Delkic",
      title: "Чиеф Славтек",
      // title: "Chief Slav",
      address: "dino@liveuptothehype.com",
      tags: ["software development"],
    },
    {
      key: "6",
      name: "Dylan Mullaney",
      title: "Solutions Architek",
      address: "dylan@liveuptothehype.com",
      tags: ["managed it"],
    },
    {
      key: "7",
      name: "William Rubiera",
      title: "Chief Solutions Architek",
      address: "william@liveuptothehype.com",
      tags: ["managed it"],
    },
  ];

  return (
    <div className="w-full h-screen flex flex-col justify-start items-center font-sans">
      <Navbar
        className="w-full"
        authState={authState}
        authDispatch={authDispatch}
      />
      <div className="w-full h-full flex flex-col justify-start items-center py-8">
        <div className="flex flex-col justify-center items-center w-10/12 gap-4">
          <p className="font-bold text-2xl py-8">Directory</p>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Directory;
