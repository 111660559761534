import { useState } from "react";
import Navbar from "./components/navbar";
import Lottie from "lottie-react";
import animationData from "./assets/animation.json";

const News = ({ authState, authDispatch }) => {
  return (
    <div className="w-full h-screen flex flex-col justify-start items-center font-sans">
      <Navbar
        className="w-full"
        authState={authState}
        authDispatch={authDispatch}
      />
      <div className="w-full h-full flex flex-col justify-start items-center py-8">
        <div className="flex flex-col justify-center items-center w-10/12 gap-4">
          <section className="bg-white text-gray-800">
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-lg text-center">
                <h2 className="text-3xl font-bold sm:text-4xl">
                  You want some news?
                </h2>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Lottie
        animationData={animationData}
        loop={true}
        className="w-full absolute bottom-0 left-0"
      />
    </div>
  );
};

export default News;
