import React, { useReducer } from "react";

const AuthContext = React.createContext();

const initAuth = {
  isAuth: false,
  role: 0,
  groups: [],
  user: {},
  samlToken: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "valid-login":
      localStorage.setItem("auth-token", action.payload.token);
      return {
        isAuth: true,
        role: action.payload.role,
        groups: action.payload.user.groups,
        user: action.payload.user,
        samlToken: action.payload.samlToken,
      };
    case "logged-out":
      localStorage.removeItem("auth-token");
      localStorage.removeItem("saml-token");
      return { isAuth: false, role: 0, groups: [], user: {}, samlToken: null };
    default:
      return initAuth;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initAuth);
  return (
    <AuthContext.Provider value={{ ...state, authDispatch: dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
